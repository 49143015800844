<template>
    <article>
        <mono-sculpting-home></mono-sculpting-home>
        <mono-sculpting-video></mono-sculpting-video>
        <mono-sculpting-feature1></mono-sculpting-feature1>
        <mono-sculpting-feature2></mono-sculpting-feature2>
        <mono-sculpting-comparison></mono-sculpting-comparison>
        <mono-sculpting-technology></mono-sculpting-technology>
        <mono-sculpting-disclaimer></mono-sculpting-disclaimer>
        <enquiry duplicate-to-g-sheet
                 g-sheet-id-key="branch"
                 v-bind:g-sheet-id="gSheetIds"
                 v-bind:treatment-id="treatmentId">
            免費專業美容顧問咨詢
        </enquiry>
    </article>
</template>

<script>
    import MonoSculptingHome from "./MonoSculpting/Home";
    import MonoSculptingVideo from "./MonoSculpting/Video";
    import MonoSculptingFeature1 from "./MonoSculpting/Feature1";
    import MonoSculptingFeature2 from "./MonoSculpting/Feature2";
    import MonoSculptingComparison from "./MonoSculpting/Comparison";
    import MonoSculptingTechnology from "./MonoSculpting/Technology";
    import Enquiry from "./Enquiry";
    import MonoSculptingDisclaimer from "./MonoSculpting/Disclaimer";

    export default {
        name: "MonoSculpting",
        components: {
            MonoSculptingDisclaimer,
            MonoSculptingTechnology,
            MonoSculptingComparison,
            MonoSculptingFeature2,
            MonoSculptingFeature1,
            MonoSculptingVideo,
            MonoSculptingHome,
            Enquiry,
        },
        metaInfo () {
            return {
                title: this.$sanitize(this.treatment.title[this.$i18n.locale]),
                meta: [{
                    "name": "description",
                    "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                    "vmid": "description",
                }, {
                    "property": "og:title",
                    "content": this.$sanitize(this.treatment.title[this.$i18n.locale]),
                    "template": "%s | marier Skincare 曼肌兒",
                    "vmid": "og:title"
                }, {
                    "property": "og:description",
                    "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                    "vmid": "og:description"
                }, {
                    "property": "og:type",
                    "content": "profile",
                    "vmid": "og:type"
                }, {
                    "property": "fb:app_id",
                    "content": "148635335285458",
                    "vmid": "fb:app_id"
                }, /*{
                    "property": "og:image",
                    "content": `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/product/image/${ this.product.images[0] }`,
                    "vmid": "og:image"

                }*/]
            }
        },
        props: {
            treatmentId: {
                type: String,
                required: true,
            }
        },
        data () {
            return {
                gSheetIds: {
                    "香港銅鑼灣羅素街38號金朝陽中心9樓902-903室": "AKfycbx5tef8q9huChgYmK5bLO65mCL_HEfbBQMzN8HC94LVOTXxcAfe", // CWB
                    "尖沙咀彌敦道132號美麗華廣場A座6樓602室": "AKfycby93s7mJnKmzHMVXiFV61PW7IbdNsPZeBjdRypSQE8cybctymo8" // TST
                },
            }
        },
        computed: {
            treatment () {
                let target = this.$store.state.mall.salon.treatments.find(
                    treatment => treatment._id === this.treatmentId
                );
                if (target) {
                    return target;
                }
                return {
                    title: {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                    subtitle: {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                    availableBranches: [],
                    images: [],
                    cover: "",
                };
            },
        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>

</style>
